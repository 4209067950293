body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  box-sizing: border-box;
}

* {
  box-sizing:border-box;
} 

*:focus {
  outline: none;
}

button {
  background-color: #0a75c2;
  padding: 5px 10px;
  color: white;
  font-weight: 700;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  height: 30px;
  padding: 0 18px;
  border: 1px solid transparent;
  border-radius: 3px;
  text-transform: uppercase;
  line-height: 22px;
  font-size: 12px;
  outline: 0;
  white-space: nowrap;
}

input[type="text"], input[type="password"] {
  border: none;
  outline: none;
  padding: 5px;
  font-size: 16px;
  cursor: text;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

.grey {
  background-color: #eeeeee;
}

.blue {
  background-color: #0a75c2;
}

.footer {
  text-align: center;
  font-size: 12px;
  opacity: .5;
  padding: 0 0 10px 0;
}

.container {
  margin: 0 auto;
  max-width: 1000px;
  padding: 20px;
  position: relative;
  padding: 70px 20px;
}

.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.contentCont {
  display: flex;
  /* flex-flow: row-reverse; */
}

.contentCont .left.large {
  max-width: 450px;
}

.contentCont .left.small {
  width: 350px;
}

.contentCont .right {
  padding: 0;
  flex: 1;
}

.contentCont .right .header {
  color: #276fc0;
  font-weight: 800;
  font-size: 16px;
  padding: 100px 0;
  text-align: left;
  max-width: 90%;
  padding-left: 8%; 
}

.contentCont .right .header .sub {
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px;
}

.company {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

.contentCont .left .header {
  margin-bottom: 20px;
}

.contentCont .left .header img {
  border-right: 1px solid #0a75c2;
  padding-right: 20px;
  vertical-align: middle;
  padding: 0 20px 0 0;
  max-width: 220px;
  max-height: 80px;
}

.contentCont .left .header .time {
  padding-left: 10px;
  vertical-align: top;
  font-size: 12px;
}

.ClockCont {
  padding: 20px;
  position: relative;
}

.Camera {
  margin-bottom: 20px;
}

.Message {
  text-align: center;
  font-weight: 800;
  color: red;
  padding-top: 5px;
}

.Photo {
  position: relative;
}

.Photo img {
  position: absolute;
  width: 100%;
}

.Camera .buts {
  text-align: center;
  padding: 10px 0;
}

.Camera .note {
  padding-top: 10px;
  font-size: 12px;
  color: black;
  text-align: center;
}

.Form {
  position: relative;
}

.Form button:disabled {
  background-color: #808080;
  cursor: not-allowed;
}

.Form .line {
  margin: 10px 0;
}

.Form input[type="text"], .Form input[type="password"] {
  font-size: 16px;
  color: black;
  width: 100%;
  min-width: 200px;
}

.Form .buts {
  text-align: center;
}

.Form .buts button {
  margin: 5px 20px;
}

.identifyLabel {
  font-size: 12px;
}

.Form .identify_first {
  margin-bottom: 10px;
  font-weight: 800;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
}

.Form .identify_first .label{
  margin-right: 10px;
  font-size: 10px;
  font-weight: 400;
}

.Form .identify_first .main{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Form .identify_first .main > div {
  display: flex;
  flex-flow: column;
}

.Form .identify_first button {
  background-color: red;
  padding: 3px;
  margin-left: 10px;
  font-size: 12px;
  height: auto;
}

.CCModal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  font-size: 14px;
}

.CCModal .body {
  position: relative;
  background-color: white;
  padding: 10px;
  font-size: 20px;
  color: black;
  border-radius: 5px;
  width: 95%;
  height: 90%;
  max-height: 90%;
  max-width: 900px;
  display: flex;
  flex-flow: column;
}

.CCModal .head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.CCModal .head .title {
  font-size: 14px;
  font-weight: 700;
}

.CCModal .selections {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
  min-height: 45px;
}

.CCModal .selections div {
  margin: 3px;
  padding: 3px;
  border: solid 1px black;
  border-radius: 5px;
}

.CCModal .selections div span {
  font-size: 14px;
}

.CCModal .selections div button {
  cursor: pointer;
  background-color: #0a75c2;
  color: white;
  padding: 5px;
  border-radius: 3px;
  border: none;
  font-size: 14px;
  margin-left: 5px;
}

.CCModal .ccname {
  text-align: center;
  margin: 10px 0;
  font-weight: 700;
}

.CCModal .pickerArea {
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  flex: 1;
  min-height: 0;
}

.CCModal .pickerArea .picker {
  max-height: 100%;
  display: flex;
  flex-flow: column;
}

.CCModal .pickerArea .submit .label {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin: 20px auto 10px auto;
  max-width: 450px;
}

.CCModal .pickerArea .submit .buts {
  text-align: center;
}

.CCModal .pickerArea .submit .buts  button {
  margin: 10px;
}

.CCModal .pickerArea .submit .buts  button.cancel {
  background-color: white;
  border: solid 1px #0a75c2;
  color: #0a75c2;
}

.CCModal .pickerArea .allCCList {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  justify-content: center;
  margin-bottom: 5px;
  align-items: center;
}

.CCModal .pickerArea .allCCList > div {
  padding: 3px;
  border-radius: 5px;
  margin: 3px;
}

.CCModal .pickerArea .allCCList > div.active {
  border: solid 1px black;
}

.CCModal .choiceCont {
  display: flex;
  min-height: 0;
}

.CCModal .pickerArea .specialButs {
  padding: 10px;
}

.CCModal .treeCont {
  flex: 1;
  overflow: auto;
}

.CCModal .treeCont .treeButton {
  font-size: 10px;
  padding: 3px 6px;
  height: 20px;
  line-height: 1;
}

@media only screen and (max-width: 520px) {
  .container {
    padding: 20px 10px;
    padding-bottom: 0;
  }

  button {
    font-size: 14px;
  }

  .contentCont {
    flex-flow: column;
  }




  .contentCont .left.large, .contentCont .left.small {
    max-width: none;
    width: 100%;
  }

  .contentCont .right {
    padding: 0;
  }

  .contentCont .right {
    justify-content: flex-start;
  }
  
  .ClockCont {
    padding: 10px;
  }

  .contentCont .right .header {
    padding: 20px 0;
    text-align: center;
    max-width: none;
    width: 100%;
  }
}


@media screen and (max-width: 768px){
 
   .col-sm-12 {
      width: 100%;
      top: 170px;
      position: relative;
      text-align: center;
  }
  .o-v {
    overflow: visible !important;
    width:100%;
    

  }
}


@media only screen and (max-width: 520px)
{
  .custom-w {
    width: 80% !important;
}
}


@media only screen and (max-width: 414px)
{
  .custom-w {
    width: 97% !important;
    margin: auto;
}
}



