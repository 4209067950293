.AttestationModal .main {
    flex: 1;
}

.CCModal.AttestationModal .body {
    overflow: auto;
}

.AttestationModal .head {
    margin-bottom: 10px;
}

.AttestationModal .main > div {
    width: 100%;
    height: 100%;
}

.AttestationModal .questionCont {
    display: flex;
    flex-flow: column;
    text-align: center;
}

.AttestationModal .questionCont > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.AttestationModal .questionCont .textBox {
    height: 45%;
    background-color: #eeeeee;
    border-radius: 5px;
    padding: 10px;
    font-size: 24px;
    font-weight: 800;
    white-space: pre-line;
    overflow: auto;
}

.AttestationModal .questionCont.summary .textBox {
    height: auto;
    font-size: 20px;
    padding: 20px 10px;
}

.AttestationModal .questionCont.summary .summaryBox {
    display: block;
    max-height: 70%;
    padding-bottom: 20px;
    overflow: auto;
}

.AttestationModal .questionCont.summary .summaryBox .title {
    margin: 10px 0;
    font-weight: 800;
}

.AttestationModal .questionCont.summary .summaryBox .total {
    font-weight: 800;
    margin: 10px 0;
    text-align: center;
}

.AttestationModal .questionCont .buttonsCont {
    flex: 1;
    padding: 10px;
    align-items: flex-start;
}

.AttestationModal .questionCont .explanationModal {
    flex: 1;
    padding: 10px;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
}

.AttestationModal .questionCont .explanationModal .top {
    font-size: 12px;
    display: flex;
    align-items: center;
}

.AttestationModal .questionCont .explanationModal .top .label {
    margin-right: 10px;
}

.AttestationModal .questionCont .explanationModal .top .answer {
    display: flex;
    align-items: center;
}

.AttestationModal .questionCont .explanationModal .top .answer .text {
    margin-right: 10px;
    font-weight: 800;
}

.AttestationModal .questionCont .explanationModal .top .answer button {
    background-color: red;
    padding: 3px;
    height: 20px;
    line-height: 0;
}

.AttestationModal .questionCont .explanationModal .bottom {
    padding: 5px 0;
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
}

.AttestationModal .questionCont .explanationModal .bottom .label {
    font-size: 14px;
    font-weight: 800;
}

.AttestationModal .questionCont .explanationModal .bottom button {
    align-self: center;
    margin-top: 10px;
}

.AttestationModal .questionCont .explanationModal textarea {
    flex: 1;
    width: 100%;
    font-size: 14px;
    resize: none;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid rgb(218, 218, 218);
}

.AttestationModal .questionCont .buttonsCont.vertical {
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
}

.p-none {
    pointer-events: none !important;
}

.AttestationModal .questionCont .buttonsCont .button {
    cursor: pointer;
    background-color: #0a75c2;
    color: white;
    font-weight: 700;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 3px;
    outline: 0;
    font-size: 20px;
    padding: 5px 10px;
    height: auto;
}

.AttestationModal .questionCont .buttonsCont > div {
    margin: 10px;
}

.AttestationModal .entries table {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.AttestationModal .entries table tbody tr:nth-of-type(2n-1) {
    background-color: #eeeeee;
}

.AttestationModal .finishedCont {
    color: #188041;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    font-weight: 800;
    text-align: center;
}